// This file is automatically compised by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

import 'jquery';

import "channels"
import 'bootstrap'
import './src/application.scss'

Rails.start()
Turbolinks.start()

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

var filters = {
  type: {activity: true, feature: true, short: true},
  availability: {online: true, offline: true, mixed: true, TBD: true},
  is_free: String(new URLSearchParams(window.location.search).get('free')),
  section: parseInt(new URLSearchParams(window.location.search).get('section')),
  talent_presences: String(new URLSearchParams(window.location.search).get('talent_presences')),
  tags: []
}

var searchInput = "";

function playMovie(e) {
  e.preventDefault();
  var play = $(e.currentTarget).parent()[0] || $('.play')[0] || $('.trailer_play')[0];
  var ds = play.dataset;

  if(ds.iframeContent) {
    if($(play).hasClass('trailer_play')) {
      $('#molinsModalTrailerBody' + ds.itemId).html(ds.iframeContent);
      $('#molinsModalTrailer' + ds.itemId).modal();
    } else {
      $('#molinsModalFilm' + ds.itemId).modal();
    }
  } else {
    if(ds.streamLink) {
      window.open(ds.streamLink);
    }
  }
}

$(document).on("click", "a.filter_tag", function(e) {
  e.preventDefault();
  var ds = this.dataset;
  var id = parseInt(this.dataset.id);
  if(filters['tags'].length == 0) {
    filters['tags'] = [id];
  } else {
    if(filters['tags'].includes(id)) {
      filters['tags'].splice(filters['tags'].indexOf(id), 1);
    } else {
      filters['tags'].push(id);
      filters['tags'].sort();
    }
  }
  setVisibleItems();
});

$(document).on("click", ".section_option", function(e) {
  var aux = this.dataset;
  var id = parseInt(this.dataset.id);

  if(id == 0) {
    delete filters['section'];
  } else {
    filters['section'] = id
  }

  $(this.parentElement.parentElement).find(".section_option").removeClass('hidden')
  $(this).addClass('hidden')

  $($(this.parentElement.parentElement).find(".current_selection")[0]).html($(this).html());

  setVisibleItems();
});

function selectScheduleSection(id) {
  if(id == 0) {
    delete filters['section'];
  } else {
    filters['section'] = id
  }

  setVisibleItems();
}

$(document).on("click", ".location_option", function(e) {
  var aux = this.dataset;
  var id = parseInt(this.dataset.id);

  if(id == 0) {
    delete filters['location'];
  } else {
    filters['location'] = id
  }

  $(this.parentElement.parentElement).find(".location_option").removeClass('hidden')
  $(this).addClass('hidden')

  $($(this.parentElement.parentElement).find(".current_selection")[0]).html($(this).html());

  setVisibleItems();
});

$(document).on("click", ".date_option", function(e) {
  var aux = this.dataset;
  var id = this.dataset.id;

  if(id == 0) {
    delete filters['date'];
  } else {
    filters['date'] = id
  }

  $(this.parentElement.parentElement).find(".date_option").removeClass('hidden')
  $(this).addClass('hidden')

  $($(this.parentElement.parentElement).find(".current_selection")[0]).html($(this).html());

  setVisibleItems();
});

$(document).on("click", '.close', function(e) {
  var id = e.currentTarget.dataset.itemId;
  $('#molinsModalTrailer' + id).modal('hide');
  $("#molinsModalTrailer" + id + " iframe").attr("src", $("#molinsModalTrailer" + id + " iframe").attr("src"));

  $('#molinsModalFilm' + id).modal('hide');
  $("#molinsModalFilm" + id + " iframe").attr("src", $("#molinsModalFilm" + id + " iframe").attr("src"));

  $('#molinsModalTrailerBody' + id).html('');
});

$(document).on("click", '.my_profile_links a', function(e) {
  e.preventDefault();
  var publicClass = $(e.currentTarget).attr('id');

  $('.my_profile_tab').each(function(i) {
    var el = $('.my_profile_tab')[i]
    if(el.id == (publicClass + '_tab')) {
      $(el).removeClass('hidden');
    } else {
      $(el).addClass('hidden');
    }
  });

  $('.tab_link').each(function(i) {
    var el = $('.tab_link')[i]
    if(el.id == (publicClass + '_link')) {
      $(el).addClass('red_font');
    } else {
      $(el).removeClass('red_font');
    }
  });
});

function hideModal() {
  $("#molinsModal").removeClass("in");
  $(".modal-backdrop").remove();
  $('body').removeClass('modal-open');
  $('body').css('padding-right', '');
  $("#molinsModal").hide();
}

$(document).on('turbolinks:load', function() {
  var play = $('.trailer_play')[0];
  if(play) {
    $('.movie_play').on('click', playMovie);
  }
});

$(document).on('turbolinks:load', function() {
  var isFree = filters['is_free'];
  $( "#filter_is_free" ).prop("checked", isFree == 'true' );

  if(filters['section'] != undefined && !isNaN(filters['section'])) {
    $(".section_option").removeClass('hidden');
    $(".section_option[data-id='" + filters['section'] + "]").addClass('hidden');

    var content = $(".section_option[data-id='" + filters['section'] + "']").html()

    $($($(".section_option[data-id='" + filters['section'] + "']")[0].parentElement.parentElement).find(".current_selection")[0]).html(content);
  }

  if($("#program").length + $("#schedule_table").length) {
    reloadFilters();
    setVisibleItems();
  };
});

$(document).on('turbolinks:load', function() {
  var play = $('.play')[0];
  if(play) {
    var ds = play.dataset;
    var now = $.now()/1000

    if(ds.startStreaming && ds.startStreaming != 0) {
      if(now > ds.startStreaming && ds.endStreaming > now) {
	$('.movie_play').on('click', playMovie);
      } else {
      $('.play').hide();
      }
    } else {
      $('.play').hide();
    }
  }
});

$(document).on('turbolinks:load', function() {
  $('.imageclip').each(function() {
    loadHighResImage(this);
  });

});

$(document).on('turbolinks:load', function() {
  $($('.sponsor_image')[0]).show();
});

window.setInterval(changeVisibleSponsor, 3000);

function changeVisibleSponsor() {
  var currentImage = $($('.sponsor_image:visible')[0])
  var i = parseInt(currentImage.attr('index'));
  var n = $('.sponsor_image').length

  if(i < n-1) {
    i = i + 1
  } else {
    i = 0
  }

  currentImage.hide();
  $($('.sponsor_image')[i]).show();
}

function loadHighResImage(elem) {
  var high_res_image = $(elem).attr('high_res_image');
  if(high_res_image) {
    var image = new Image();
    image.addEventListener('load', () => {elem.src = high_res_image})
    image.src = high_res_image;
  }
}

$(document).on('turbolinks:load', function() {
  if($('.like_container').length > 0 && $('body')[0].dataset.loggedUserId) {
    $.ajax({
      url: '/api/v1/likes',
      type: 'GET',
      dataType: 'json',
      success: function(data) {
	data['likes'].forEach(renderAsLiked);
      }
    });
  }
});

$(document).on('turbolinks:load', function() {
  $('.searchableItem').each(function(i) {
    var ds = this.dataset
    var now = $.now()/1000
    if (now > ds.itemStartStreaming && now < ds.itemEndStreaming) {
      var s = $(this).find('.streaming_dates')
      if (!$(s).text().startsWith("AVAILABLE")) {
	$(s).prepend("AVAILABLE | ");
      }

    }
  });
});

function renderAsLiked(item, index) {
  var el = $('.like_container[data-likeable-type=' + item.likeable_type + '][data-likeable-id=' + item.likeable_id + '] a.like')[0];
  if (el) {
    var ds = el.dataset;
    var img = $('.like_container[data-likeable-type=' + item.likeable_type + '][data-likeable-id=' + item.likeable_id + '] a.like img')[0];
    el.setAttribute('data-action', 'delete');
    el.setAttribute('data-likeable-id', ds.likeableId);
    el.setAttribute('data-likeable-type', ds.likeableType);
    el.setAttribute('data-url', "/api/v1/likes/" + item.id);
    img.setAttribute('src', ds.unlikeIcon);
  }
}

function afterCreateLike(ds) {
  return function(data) {
    if (data.id != null) {
      var el = $("[data-likeable-id=" + ds.likeableId + "] a.like")[0]
      var img = $("[data-likeable-id=" + ds.likeableId + "] a.like img")[0]
      el.setAttribute('data-action', 'delete');
      el.setAttribute('data-likeable-id', ds.likeableId);
      el.setAttribute('data-likeable-type', ds.likeableType);
      el.setAttribute('data-url', "/api/v1/likes/" + data.id);
      img.setAttribute('src', ds.unlikeIcon);
    } else {

    }
  };
}

function afterDeleteLike(ds) {
  return function(data) {
    var el = $("[data-likeable-id=" + ds.likeableId + "] a.like")[0]
    var img = $("[data-likeable-id=" + ds.likeableId + "] a.like img")[0]
    el.setAttribute('data-action', 'create');
    el.setAttribute('data-likeable-id', ds.likeableId);
    el.setAttribute('data-likeable-type', ds.likeableType);
    el.setAttribute('data-url', "/api/v1/likes");
    img.setAttribute('src', ds.likeIcon);
  }
}

$(document).on("click", "a.like", function(e) {
  e.preventDefault();
  var ds = this.dataset;
  if($('body')[0].dataset.loggedUserId) {

    if (ds.action == 'create') {
      $.post(ds.url, { id: ds.likeableId, type: ds.likeableType }, afterCreateLike(ds));
    } else {
      $.ajax({
	url: ds.url,
	type: 'DELETE',
	dataType: 'json',
	success: afterDeleteLike(ds)
      });
    }
  } else {
    window.location.href = $('body')[0].dataset.loginPath;
  }
});

/*
window.onload = function () {
  var program = $("#program")[0]
  $(".loading").remove();
  if(program) {
    for(var i = 0; i < itemsToRender.length; i++) {
      program.innerHTML += itemsToRender[i];
    }
    $('.imageclip').each(function() {
      loadHighResImage(this);
    });
  }
}
*/

$(document).on("change", "#filter_activity", function(e) {
  if(this.checked) {
    filters['type']['activity'] = true;
  } else {
    filters['type']['activity'] = false;
  }
  setVisibleItems();
});

$(document).on("change", "#filter_feature", function(e) {
  if(this.checked) {
    filters['type']['feature'] = true;
  } else {
    filters['type']['feature'] = false;
  }
  setVisibleItems();
});

$(document).on("change", "#filter_short", function(e) {
  if(this.checked) {
    filters['type']['short'] = true;
  } else {
    filters['type']['short'] = false;
  }
  setVisibleItems();
});

$(document).on("change", "#filter_is_free", function(e) {
  if(this.checked) {
    filters['is_free'] = true;
  } else {
    filters['is_free'] = undefined;
  }
  setVisibleItems();
});

$(document).on("change", "#filter_talent_presences", function(e) {
  if(this.checked) {
    filters['talent_presences'] = true;
  } else {
    filters['talent_presences'] = undefined;
  }
  setVisibleItems();
});

$(document).on("change", ".availability_input", function(e) {
  var type = $(this).attr("id").split("_")[1]

  if(this.checked) {
    filters['availability'][type] = true;
  } else {
    filters['availability'][type] = false;
  }
  setVisibleItems();
});

$(document).on("change", ".length_input", function(e) {
  var type = $(this).attr("id").split("_")[1]

  if(this.checked) {
    filters['length_type'][type] = true;
  } else {
    filters['length_type'][type] = false;
  }
  setVisibleItems();
});

$(document).on("change", ".dropdown_filters select", function(e) {
  var type = $(this).attr("id").split("_")[1]
  var value = $(this).val();

  if(value == '') {
    delete filters[type];
  } else {
    filters[type] = value;
  }

  setVisibleItems();
});

function reloadFilters() {
  filters = {
    type: {activity: true, feature: true, short: true},
    availability: {online: true, offline: true, mixed: true, TBD: true},
    is_free: String(new URLSearchParams(window.location.search).get('free')),
    section: parseInt(new URLSearchParams(window.location.search).get('section')),
    talent_presences: String(new URLSearchParams(window.location.search).get('talent_presences')),
    tags: []
  }
}

function setVisibleItems() {
  $(".searchResult").show();
  var keys = Object.keys(filters['type']);
  for(var i = 0; i < keys.length; i++) {
    if(filters['type'][keys[i]] == false){
      $(".searchableItem").filter('div[data-item-type=' + keys[i] + ']').hide();
    }
  }

  filters['availability']['mixed'] = (filters['availability']['online'] || filters['availability']['offline'])

  keys = Object.keys(filters['availability']);
  for(var i = 0; i < keys.length; i++) {
    if(filters['availability'][keys[i]] == false) {
      $(".searchableItem").filter('div[data-item-availability=' + keys[i] + ']').hide();
    }
  }

  var regex = new RegExp(searchInput, 'i');
  if(searchInput.length > 0) {
    $(".searchableItem").filter(function(index, item) {
      return (!item.dataset['itemTitle'] || !item.dataset['itemTitle'].match(regex))
    }).hide();
  }

  var date = filters['date'];
  if(date != undefined) {
    $(".searchableItem").filter(function(index, item) {
      return item.dataset['itemDate'] != date
    }).hide();
  }

  var location = filters['location'];
  if(location != undefined) {
    $(".searchableItem").filter(function(index, item) {
      return item.dataset['itemLocation'] != location
    }).hide();
  }

  var section = filters['section'];
  if(section != undefined && !isNaN(section)) {
    $(".searchableItem").filter(function(index, item) {
      var sections = $.parseJSON(item.dataset['itemSections']);
      return !sections.includes(parseInt(section));
    }).hide();
  }

  var isFree = filters['is_free'];
  if (isFree != undefined && isFree != 'false' && isFree != 'null') {
    $(".searchableItem").filter(function(index, item) {
      return !(item.dataset['itemIsfree'] == 'true')
    }).hide();
  }

  var hasTalentPresences = filters['talent_presences'];
  if (hasTalentPresences != undefined && hasTalentPresences != 'false' && hasTalentPresences != 'null') {
    $(".searchableItem").filter(function(index, item) {
      return !(item.dataset['itemTalentpresences'] == 'true')
    }).hide();
  }

  var tags = filters['tags'];

  $(".tag").filter(function(index, item) {
    $(item).removeClass('active_tag');
  });

  if(filters['tags'].length > 0) {
    $(".searchableItem").filter(function(index, item) {
      var itemTags = $.parseJSON(item.dataset['itemTags']);
      let intersection = filters['tags'].filter(x => itemTags.includes(x));
      return intersection.length < filters['tags'].length
    }).hide();

    $(".tag").each(function() {
      var tagds = this.dataset;

      if(filters['tags'].includes(parseInt(tagds.tagId))) {
	$(this).addClass('active_tag');
      }
    });
  }

  $('.section_content').each(function() {
    var visibleItems = $(this).find('.searchableItem').filter(function(index, item) {
      return item.style.display != 'none'
    }).length

    if (visibleItems > 0){
      $(this).show();
    } else {
      $(this).hide();
    }
  });
}

function delay(fn, ms) {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

$(document).on("keyup", "#search-program", delay(function(e) {
  searchInput = this.value;
  setVisibleItems();
}, 500));
